import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Footer, { LinkItem } from "@/components/Footer";
import ZoomIn from "@/components/ZoomIn";
import Banner from "@/components/Banner";
import { PreviousCollectionPageQuery } from "../../graphql-types";

interface Props {
  data: PreviousCollectionPageQuery;
}

const CollectionPage = ({ data }: Props) => {
  const { headerLinks, footerLinks } = data.global || {};
  const { nodes } = data.products;

  return (
    <Layout
      scroll="default"
      header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}
      hasTopSpacing
    >
      <Seo title="Collection | Fall / Winter 2022" />
      <Banner title="Fall / winter" subtitle="Collection">
        2022
      </Banner>
      <Layout.Section>
        <div
          className="grid"
          style={{
            gridTemplateColumns: "repeat(4, 1fr)",
            gridAutoColumns: "16vw",
            gap: "0.5rem",
          }}
        >
          {nodes.map((image, index) => {
            // @ts-expect-error Codegen doesn't know about imageSharp type.
            const gatsbyImage = getImage(image);

            if (!gatsbyImage) {
              return null;
            }

            const shouldBeLarge = [2, 6, 7, 11, 15].some(large => (index + 1 - large + 15) % 15 === 0);

            return (
              <ZoomIn
                className={classNames("row-span-1 col-span-2", {
                  "md:col-span-1": !shouldBeLarge,
                  "md:row-span-1": !shouldBeLarge,
                })}
                key={index}
              >
                {zoom => (
                  <div
                    // @note: This solves an image issue. There are no tailwind class
                    // for this.
                    style={{ lineHeight: 0 }}
                    className="h-full"
                  >
                    {React.cloneElement(
                      // @todo How can I allow scrolling horizontally?
                      <GatsbyImage image={gatsbyImage} alt={`Hello ${index}`} objectFit={zoom ? "cover" : "cover"} />,
                      {
                        className: classNames({
                          "h-screen md:min-h-screen md:h-auto": zoom,
                          "h-full": !zoom,
                        }),
                      }
                    )}
                  </div>
                )}
              </ZoomIn>
            );
          })}
        </div>
      </Layout.Section>
      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default CollectionPage;

export const pageQuery = graphql`
  query PreviousCollectionPage {
    products: allFile(filter: { sourceInstanceName: { eq: "previous-collection" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    global: dataJson {
      ...Global
    }
  }
`;
